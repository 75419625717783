import { getToken } from "../utils/prefix";

export async function selectedNotification(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/notification/selectall`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );
  const dataRespone = await res.json();
  return dataRespone;
}

export async function checkReadNotification(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/notification/updatestatus`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function checkReadAllNotification(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/notification/updatestatusall`,
    {
      method: "GET",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function sendNotification(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/notification/create`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function countNotificationUnRead(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/notification/getallunread`,
    {
      method: "GET",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}
