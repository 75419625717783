import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { getSetting, manualBackup, setSetting } from "../../services/backup";

type Props = {};

const BackupPage = (props: Props) => {
  const [form] = Form.useForm();
  const [fullBackupEnable, setFullBackupEnable] = useState(false);
  const [incrementBackupEnable, setIncrementBackupEnable] = useState(false);
  const [deleteEnable, setDeleteEnable] = useState(false);
  const [indexEnable, setIndexEnable] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openRestoreConfirm, setOpenRestoreConfirm] = useState(false);
  const [openSaveConfirm, setOpenSaveConfirm] = useState(false);

  const { confirm } = Modal;

  const { Option } = Select;

  const showConfirmBackupModal = () => {
    confirm({
      title: "Backup confirmation",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to back up now?",
      async onOk() {
        let result = await manualBackup();
        if (result?.success == true) {
          message.success("Backup successfully", 5);
        } else {
          setOpenConfirm(false);
          message.error("Cannot back up the data. Please try again.", 5);
        }
      },
      onCancel() {},
    });
  };

  const showConfirmSaveModal = () => {
    confirm({
      title: "Saving setting confirmation",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to save backup setting ?",
      onOk() {
        form.submit();
      },
      onCancel() {},
    });
  };

  const dayOptions = [
    { value: "0", label: "Sunday" },
    { value: "1", label: "Monday" },
    { value: "2", label: "Tuesday" },
    { value: "3", label: "Wednesday" },
    { value: "4", label: "Thursday" },
    { value: "5", label: "Friday" },
    { value: "6", label: "Saturday" },
    { value: "7", label: "Everyday" },
  ];

  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2)
      .toString()
      .padStart(2, "0");
    const minute = i % 2 === 0 ? "00" : "30";
    const label = `${hour}:${minute}`;
    return { value: label, label };
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getSetting();
      let full_backup_value = data.filter(
        (e: any) => e.key === "full_backup_schedule"
      );
      full_backup_value =
        full_backup_value[0]?.value == ""
          ? ""
          : JSON.parse(full_backup_value[0]?.value);

      let delete_value = data.filter((e: any) => e.key === "backup_cleansing");

      delete_value =
        delete_value[0]?.value == "" ? "" : JSON.parse(delete_value[0]?.value);

      form.setFieldsValue({
        full_backup_day: full_backup_value?.day,
        full_backup_time: full_backup_value?.time,
        full_backup_enable: full_backup_value?.enable == 1 ? true : false,
        // increment_backup_day: inc_backup_value?.day,
        // increment_backup_time: inc_backup_value?.time,
        // increment_backup_enable: inc_backup_value?.enable == 1 ? true : false,
        delete_day: delete_value?.day,
        delete_time: delete_value?.time,
        delete_enable: delete_value?.enable == 1 ? true : false,
        duration: delete_value?.duration,
      });
      setFullBackupEnable(full_backup_value?.enable == 1);
      // setIncrementBackupEnable(inc_backup_value?.enable == 1);
      setDeleteEnable(delete_value?.enable == 1);
    };
    fetchData();
  }, []);

  const onFinish = async (v: any) => {
    Object.entries(v).forEach(([key, value]) => {
      if (value === undefined) {
        v[key] = "";
      }
    });

    let result = await setSetting({
      data: [
        {
          key: "full_backup_schedule",
          value: `{"enable":${fullBackupEnable == true ? 1 : 0}, "day": "${
            v.full_backup_day
          }","time":"${v.full_backup_time}"}`,
        },
        {
          key: "inc_backup_schedule",
          value: `{"enable":${incrementBackupEnable == true ? 1 : 0}, "day": "${
            v.increment_backup_day
          }","time":"${v.increment_backup_time}"}`,
        },
        {
          key: "backup_cleansing",
          value: `{"enable":${deleteEnable == true ? 1 : 0},"day":"${
            v.delete_day
          }","time":"${v.delete_time}", "duration":"${v.duration}" }`,
        },
      ],
    });

    if (result?.success == true) {
      message.success("Saved successfully.", 5);
    } else {
      message.error("Cannot save data. Please try again.", 5);
    }
  };

  return (
    <div className="content-container">
      <Row>
        <Col>
          <h1 className="header-topic">{"Backup"}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <Card title="Full backup" headStyle={{ fontWeight: "bold" }}>
              <Row style={{ marginBottom: "10px", marginTop: "-10px" }}>
                <Col span={24}>
                  <Form.Item
                    name="full_backup_enable"
                    valuePropName="checked"
                    style={{ marginBottom: "0px" }}
                  >
                    <Checkbox
                      onChange={(e) => setFullBackupEnable(e.target.checked)}
                    >
                      Enable
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Form.Item name="full_backup_day">
                    <Select
                      placeholder="Day"
                      options={dayOptions}
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="full_backup_time">
                    <Select
                      placeholder="Time"
                      options={timeOptions}
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <Space wrap>
                <Button
                  // htmlType="submit"
                  onClick={() => showConfirmSaveModal()}
                  style={{
                    background: "#1890ff",
                    border: "#1890ff",
                    color: "#fff",
                    width: "150px",
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{
                    background: "#0c8827",
                    border: "#0c8827",
                    color: "#fff",
                    width: "150px",
                  }}
                  onClick={() => showConfirmBackupModal()}
                >
                  Backup
                </Button>
              </Space>
            </div>
          </Form>
        </Col>
        <Col lg={3} xl={3} xxl={3}></Col>
      </Row>
    </div>
  );
};

export default BackupPage;
