import { useEffect, useMemo, useState } from "react";
import "../styles/index.less";
import {
  Card,
  Col,
  Image,
  Row,
  Input,
  Modal,
  Space,
  Spin,
  Select,
  Empty,
  Form,
  Dropdown,
  Button,
} from "antd";
import useNotification from "../../../components/Notification";
import NoImage from "../../../assets/images/logoMemo.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { api_getAllVideo } from "../../../services/video";
import moment from "moment";
import TagList, { Container } from "../../../components/TagList";
import ContainerDimensions from "react-container-dimensions";
import { translate } from "../../../utils/libs/translate";
import { t } from "i18next";
import {
  EyeOutlined,
  MoreOutlined,
  SettingOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  RedoOutlined,
  TableOutlined
} from "@ant-design/icons";
import ModalSetting from "./component/modalSetting";
import PaginationComponent from "../../../components/Pagination";
import { useSocket } from "../../../utils/socketContext/socketProvider";
import { resend_updateTask, updateTask } from "../../../services/task";
import { sendNotification } from "../../../services/notification";
import { useAuth } from "../../../utils/Auth/AuthWrapper";
import TableList from "./component/tableList";
import { pageSearch } from "../../../utils/atoms";
import { useRecoilState } from "recoil";
moment.updateLocale("th", {
  day: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Today at] LT",
    lastWeek: "[Last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "dddd DD [at] LT",
  },
});

const AllVideoPage = ({}) => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useNotification();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useRecoilState(pageSearch);
  // const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState(8);
  const [form] = Form.useForm();
  const [form_update_task] = Form.useForm();
  const [sorts, setSorts] = useState("created_timestamp");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { confirm } = Modal;
  const { user } = useAuth();
  let [firstLoadPage,setFirstLoadPage]= useState(true)

  let interval;

  //socket staete
  const socket = useSocket();
  const [socketUpdate, setSocketUpdate] = useState(false);
  const [socketCreate, setSocketCreate] = useState(false);
  const [socketStatus, setSocketStatus] = useState(false);
  const [socketTest, setSocketTest] = useState();
  const [socketDataStatus, setSocketDataStatus] = useState();
  const [refreshInterval, setRefreshInterval] = useState(0)
  const [tableStyle,setTableStyle] = useState(true)

  //function
  const getData = async () => {
    try {
      setIsLoading(true);
      const form_sort = form.getFieldValue("sort");
      const form_filter = form.getFieldValue("filter");
      const form_order = form.getFieldValue("order");

      const dataInfo = {
        page: currentPage,
        size: pageSize,
        filter: form_filter != undefined ? form_filter : null,
        order_by:
          form_sort == undefined && form_order == undefined
            ? [`created_timestamp desc`]
            : [
                `${form_sort == undefined ? "created_timestamp" : form_sort} ${
                  form_order == undefined ? "asc" : form_order
                }`,
              ],
        query: searchValue,
        user_id: location.pathname == "/video/all" ? null : "test",
        exportData: false,
      };
      const res = await api_getAllVideo(dataInfo);
      if (res.success === true) {
        setData(res.data);
        setTotal(res.meta.total);
        if (res.data == null || res.data.length === 0) {
          setCurrentPage(1);
        }
        delayLoading();
      } else {
        delayLoading();
        openNotification("error", "Cannot get videos", "");
      }
    } catch (e) {
      delayLoading();
      openNotification("error", "Cannot get videos", "");
      console.error(e);
    }
  };

  const delayLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onSearch = (value) => {
    setCurrentPage(1);
    setSearchValue(value);
  };

  const onSelect = (value) => {
    setPageSize(value);
  };

  const deleteVideo = (task_id, name) => {
    confirm({
      title: translate("Are you sure to delete this video?"),
      icon: <ExclamationCircleFilled className="" />,
      okText: translate("Yes"),
      okType: "danger",
      cancelText: translate("No"),
      onOk: async () => {
        try {
          const res = await updateTask({ task_id: task_id, status: 0 });
          if (res.status === 200 || res.status === "200") {
            getData();
            //create notification message
            const res_noti = await sendNotification({
              content: `${user.user_name} ได้ลบวิดีโอ ${name}`,
              type: "delete",
            });
            openNotification("success", "Delete successfully", "");
          } else {
            openNotification("error", "Cannot Delete this video", "");
          }
        } catch (e) {
          console.error("error", e);
        }
      },
      onCancel() {
      },
    });
  };
  //dropdown menu
  const items = [
    {
      label: "Setting",
      key: "1",
      className: "primary",
      icon: <SettingOutlined />,
      onClick: ({ item, key, keyPath, domEvent }) => {
        setOpen(true);
      },
    },
    {
      label: "Delete",
      key: "2",
      className: "danger",
      icon: <DeleteOutlined />,
      onClick: ({ item, key, keyPath, domEvent }) => {
      },
    },
  ];

  const items2 = [
    {
      label: "Setting",
      key: "1",
      className: "primary",
      icon: <SettingOutlined />,
      onClick: ({ item, key, keyPath, domEvent }) => {
        setOpen(true);
      },
    },
    {
      label: "Re-Upload",
      key: "3",
      className: "primary",
      icon: <RedoOutlined  />,
      onClick: ({ item, key, keyPath, domEvent }) => {

      },
    },
    {
      label: "Delete",
      key: "2",
      className: "danger",
      icon: <DeleteOutlined />,
      onClick: ({ item, key, keyPath, domEvent }) => {

      },
    },

  ];

  const menuFil1 = [
    {
      value: "desc",
      label: "Newest to Oldest",
    },
    {
      value: "asc",
      label: "Oldest to Newest",
    },
  ];

  const menuFil2 = [
    {
      value: "asc",
      label: "A -> Z",
    },
    {
      value: "desc",
      label: "Z -> A",
    },
  ];

  const menuFil3 = [
    {
      value: "asc",
      label: "shortest length",
    },
    {
      value: "desc",
      label: "longest length",
    },
  ];

  const socketFunction = () => {

    socket.socket.on("new_video_list", (data) => {
      setSocketCreate(true);
    });

    socket.socket.on("update_video_list", (new_data) => { 
      setSocketUpdate(true);
      setSocketTest(new_data);
    });

  };

  const getTaskUpdateTask = async() => {
    const form_sort = form.getFieldValue("sort");
    const form_filter = form.getFieldValue("filter");
    const form_order = form.getFieldValue("order");
    const dataInfo = {
      page: currentPage,
      size: pageSize,
      filter: form_filter != undefined ? form_filter : null,
      order_by:
        form_sort == undefined && form_order == undefined
          ? [`created_timestamp desc`]
          : [
              `${form_sort == undefined ? "created_timestamp" : form_sort} ${
                form_order == undefined ? "asc" : form_order
              }`,
            ],
      query: searchValue,
      user_id: location.pathname == "/video/all" ? null : "test",
      exportData: false,
    };
    const result_task = await api_getAllVideo(dataInfo);

    // const res = data.map((value) => {
    const res = result_task.data.map((value) => {
      if (socketTest.task_id == value.task_id) {
        return { ...value, title: socketTest.title != null ? socketTest.title:value.title };
      } else {
        return value;
      }
    });
    setData(res);
  };

  const getTaskCreateTask = async() => {
    try{
      const form_sort = form.getFieldValue("sort");
      const form_filter = form.getFieldValue("filter");
      const form_order = form.getFieldValue("order");

      const dataInfo = {
        page: currentPage,
        size: pageSize,
        filter: form_filter != undefined ? form_filter : null,
        order_by:
          form_sort == undefined && form_order == undefined
            ? [`created_timestamp desc`]
            : [
                `${form_sort == undefined ? "created_timestamp" : form_sort} ${
                  form_order == undefined ? "asc" : form_order
                }`,
              ],
        query: searchValue,
        user_id: location.pathname == "/video/all" ? null : "test",
        exportData: false,
      };
      const res = await api_getAllVideo(dataInfo);
      if (res.success === true) {
        setData(res.data);
        setTotal(res.meta.total);
        if (res.data == null || res.data.length === 0) {
          setCurrentPage(1);
        }
      } else {
        openNotification("error", "Cannot get videos", "");
      }
    }catch(e){
    }
  };

  const getTaskReloadStatus = () => {
    if(socketDataStatus.org == user.org_id){
      getData();
    }
  }

  const selectData = useMemo(() => {
    return data 
  },[data])

  useEffect(() => {
    socket.socket.emit("join_room", "table_list");
    if(firstLoadPage == true){
      form.resetFields();
      setFirstLoadPage(false)
    }
    getData();
  }, [currentPage, searchValue, pageSize, location.pathname]);

  useEffect(() => {
    socketFunction();

    if (socketUpdate) {
      getTaskUpdateTask();
      setSocketUpdate(false);
    }

    if (socketCreate) {
      getTaskCreateTask();
      setSocketCreate(false);
    }

  }, [socket.socket, socketUpdate, socketCreate,socketStatus]);

  const fetchMetrics = async() => {
    // retrieve and then setData()
    const form_sort = form.getFieldValue("sort");
    const form_filter = form.getFieldValue("filter");
    const form_order = form.getFieldValue("order");
    const dataInfo = {
      page: currentPage,
      size: pageSize,
      filter: form_filter != undefined ? form_filter : null,
      order_by:
        form_sort == undefined && form_order == undefined
          ? [`created_timestamp desc`]
          : [
              `${form_sort == undefined ? "created_timestamp" : form_sort} ${
                form_order == undefined ? "asc" : form_order
              }`,
            ],
      query: searchValue,
      user_id: location.pathname == "/video/all" ? null : "test",
      exportData: false,
    };
    const result_task = await api_getAllVideo(dataInfo);
    setData(result_task.data)
  }

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(fetchMetrics, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  return (
    <div className="content-container h-100">
      <>{contextHolder}</>
      <Row wrap={true} gutter={[10, 10]} className="jus-between">
        <Col>
          <h1 className="header-topic">{location.pathname == "/video/all" ? t("All Video") : t("My Video")}</h1>
        </Col>
        <Col className="mr-10">
          <Row>
            <Col>
              <Space wrap>
                <Form layout="inline" className="max-w-none" form={form} initialValues={{filter:"all",sort:"created_timestamp",order:"desc"}}>
                  <Form.Item label="Filter By" name="filter">
                    <Select
                      className="filter-by w-140"
                      // defaultValue="All"
                      onSelect={(value) => {
                        getData();
                      }}
                      options={[
                        { value: "all", label: "All" },
                        { value: 4, label: "Processing" },
                        { value: 7, label: "Ready To View" },
                        { value: 99, label: "Fail Upload" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label="Sort By" name="sort">
                    <Select
                      className="filter-by w-120"
                      // defaultValue="created_timestamp"
                      onSelect={(value) => {
                        getData();
                      }}
                      onChange={(value) => {
                        form.setFieldsValue({
                          order:
                            value == "title" || value == "duration"
                              ? "asc"
                              : "desc",
                        });
                        setSorts(value);
                      }}
                      // bordered={false}
                      options={[
                        {
                          value: "created_timestamp",
                          label: "Date",
                        },
                        { value: "title", label: "Name" },
                        {
                          value: "duration",
                          label: "Length",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label="Order By" name="order">
                    <Select
                      className="filter-by w-160"
                      onSelect={(value) => {
                        getData();
                      }}
                      options={
                        sorts == "created_timestamp"
                          ? menuFil1
                          : sorts == "title"
                          ? menuFil2
                          : sorts == "duration"
                          && menuFil3
                      }
                    />
                  </Form.Item>
                </Form>
              </Space>
            </Col>
            <Col className="mr-10">
              <Button onClick={()=>setTableStyle(!tableStyle)}><TableOutlined /></Button>
            </Col>
            <Col>
              <Input.Search
                className="input-search"
                placeholder={translate("Search")}
                onSearch={onSearch}
                enterButton
                allowClear
                style={{ marginBottom: "0.5em" }}
              />
            </Col>
          </Row>
        </Col>
        
      </Row>
      <Row className="w-100per jus-around">
        {isLoading ? (
          <div className="loading-spin">
            <Spin />
          </div>
        ) : (
          <>
          {tableStyle == true ? 
            <Row gutter={[32, 32]} className="mt-1r w-100per" justify={"start"}>
            {
              selectData &&
              selectData.map((d) => {
                  return (
                    <Col
                      key={d.task_id}
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 8 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                    >
                      <Card
                        cover={
                          <div className="card-container">
                            <Image
                              preview={false}
                              className="card-img"
                              alt="video-profile"
                              height={170}
                              width={"100%"}
                              src={
                                d.image_path != ""
                                  ? `${process.env.REACT_APP_IMG_PATH}${d.image_path}`
                                  : NoImage
                              }
                              onClick={() => {
                                if (d.status == process.env.REACT_APP_READY_TO_VIEW) {
                                  navigate(`/video/${d.task_id}`);
                                } else {
                                  // alert("not ready");
                                  openNotification(
                                    "error",
                                    "Video not ready to view",
                                    ""
                                  );
                                }
                              }}
                            />
                            <div
                              className="status_video"
                              style={
                                d.status == process.env.REACT_APP_ERR
                                  ? {
                                      backgroundColor: "#fa4851",
                                    }:
                                d.status == process.env.REACT_APP_READY_TO_VIEW 
                                  ? {
                                      backgroundColor: "#5EC200CC",
                                    }
                                  :{
                                      backgroundColor: "#f5bf42",
                                    }
                              }
                            >
                                {
                                  d.status == process.env.REACT_APP_ERR ? "Fail Upload" 
                                  :d.status == process.env.REACT_APP_READY_TO_VIEW ?"Ready To View" 
                                  :`Processing ${d.avg_status == null ? 20:d.avg_status}%`
                                }
                            </div>
                            <div className="card-duration">
                              {new Date(d.duration * 1000)
                                .toISOString()
                                .slice(11, 19)}
                            </div>
                          </div>
                        }
                        bordered={false}
                      >
                        <Row gutter={[8, 8]}>
                          <Col xs={22}>{d.title}</Col>
                          <Col xs={2}>
                            <Dropdown
                              menu={
                              {
                                      items:d.status==99?items2:items,
                                      onClick: async(a) => {
                                        if (a.key == "1") {
                                          form_update_task.setFieldsValue({
                                            task_id: d.task_id,
                                            name: d.title,
                                            tag: d.tag
                                              ? d.tag.map((v) => v.name)
                                              : [],
                                          });
                                        }else if (a.key == "3") {
                                          const res = await resend_updateTask({task_id:d.task_id})
                                        } else {
                                          deleteVideo(d.task_id, d.title);
                                        }

                                      },
                                    }
                              }
                              placement="bottomRight"
                              trigger={["click"]}
                            >
                              <a
                                onClick={(e) => e.preventDefault()}
                                className="btn-more"
                              >
                                <Space>
                                  <MoreOutlined className="cursor-pointer float-r" />
                                </Space>
                              </a>
                            </Dropdown>
                          </Col>
                          <Col xs={24} className="display-tag">
                            <Container>
                              <ContainerDimensions>
                                {({ width }) => (
                                  <TagList
                                    parentWidth={width}
                                    tags={d.tag}
                                    setSearchValue={setSearchValue}
                                  />
                                )}
                              </ContainerDimensions>
                            </Container>
                          </Col>
                          <Col xs={24} className="opac-08 ">
                            {d.name}
                          </Col>
                          <Col xs={20} className="opac-08 ">
                            {moment(d.created_timestamp).fromNow()}
                          </Col>
                          <Col xs={4}>
                            <a
                              className="float-r"
                              onClick={() => {
                                if (d.status == process.env.REACT_APP_READY_TO_VIEW) {
                                  navigate(`/video/${d.task_id}`);
                                } else {
                                  // alert("not ready");
                                  openNotification(
                                    "error",
                                    "Video not ready to view",
                                    ""
                                  );
                                }
                              }}
                            >
                              <Space>
                                <EyeOutlined className="lineHeight" />
                                View
                              </Space>
                            </a>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              // )
            }
            {data && data.length === 0 && (
              <div className="container-empty">
                <Empty />
              </div>
            )}
            </Row>
            :
            <TableList dataSource={selectData} deleteVideo={deleteVideo} form={form_update_task} setOpen={setOpen}/>
          }
          </>
         
        )}
      </Row>
      {/* pagination */}
      {total > 8 && (
        <PaginationComponent
          total={total}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onSelect={onSelect}
        />
      )}
      {/* modal setting */}
      <ModalSetting
        open={open}
        setOpen={setOpen}
        form={form_update_task}
        getData={getData}
      />
    </div>
  );
};

export default AllVideoPage;
