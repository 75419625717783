import {
  DownOutlined,
  UploadOutlined,
  UpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Form, Input, Upload } from "antd";
import { translate } from "../utils/libs/translate";
import useNotification from "./Notification";

const ProfileAvatar = ({ src, icon }: any) => {
  return (
    <Avatar
      className="avatar"
      size={{
        xs: 40,
        sm: 64,
        md: 80,
        lg: 100,
        xl: 150,
        xxl: 200,
      }}
      src={src}
      {...(icon ? (icon = { icon }) : null)}
    />
  );
};

const Uploader = ({ form, previewImage, setPreviewImage }: any) => {
  const { openNotification, contextHolder } = useNotification();

  const handleChange = (image: any) => {
    const reader = new FileReader();
    form.setFieldsValue({
      profile: image.file.originFileObj,
    });
    reader.readAsDataURL(image.file.originFileObj);
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
  };

  return (
    <Upload
      name="profile"
      listType="picture-circle"
      className="profile-uploader"
      showUploadList={false}
      action={""}
      customRequest={() => {}}
      onChange={handleChange}
      maxCount={1}
      beforeUpload={(file) => {
        const isImage = file.type === "image/jpeg" || file.type === "image/png";
        if (!isImage) {
          openNotification(
            "error",
            `${file.name} is not an image file file`,
            ""
          );
        }
        return isImage || Upload.LIST_IGNORE;
      }}
    >
      {contextHolder}
      {previewImage ? (
        <ProfileAvatar src={previewImage} icon={null} />
      ) : (
        <UploadOutlined />
      )}
    </Upload>
  );
};

const ProfileForm = ({
  form,
  onFinish,
  isEdit,
  previewImage,
  setPreviewImage,
  imageUrl,
  userData,
  togglePassword,
  passwordFormVisible,
}: any) => {
  const validatePassword = {
    pattern: /.{8,}/,
    message: translate("Password must contain at least 8 characters"),
  };
  return (
    <Form className="profile-form" onFinish={onFinish} form={form}>
      <Form.Item className="profile-pic" label="" name="profile">
        {isEdit ? (
          <Uploader
            form={form}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
          />
        ) : (
          <ProfileAvatar src={imageUrl} icon={<UserOutlined />} />
        )}
      </Form.Item>
      <Form.Item
        label={isEdit ? translate("Name") : ""}
        name="user_name"
        rules={[
          {
            required: true,
            message: translate("Please enter your name"),
          },
        ]}
        initialValue={userData.user_name}
      >
        <Input className="profile-input" bordered={isEdit} disabled={!isEdit} />
      </Form.Item>
      <div style={{ padding: "0 20px 0 20px" }}>
        <div className="profile-form-group">
          <label className="profile-label">{translate("Email")}</label>
          <p className="profile-text">{userData.user_email}</p>
        </div>
        <div className="profile-form-group">
          <label className="profile-label">{translate("Organization")}</label>
          <p className="profile-text">{userData.org_name}</p>
        </div>
        <div className="profile-form-group">
          <label className="profile-label">{translate("Role")}</label>
          <p className="profile-text">{translate(userData.role_name)}</p>
        </div>
      </div>
      {isEdit && (
        <div className="flex flex-col">
          <Button
            type="link"
            className="profile-text text-primary"
            onClick={togglePassword}
          >
            <span>
              {translate("change password")}
              {passwordFormVisible ? <UpOutlined /> : <DownOutlined />}
            </span>
          </Button>
          {passwordFormVisible && (
            <>
              <Form.Item
                label={translate("Password")}
                name="newpassword"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: translate("Please enter your password"),
                  },
                  validatePassword,
                ]}
              >
                <Input.Password type={"password"} className="profile-text" />
              </Form.Item>
              <Form.Item
                label={translate("Confirm Password")}
                name="confirm"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: translate("Please confirm your password"),
                  },
                  validatePassword,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newpassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(translate("Password must be match"))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password type={"password"} className="profile-text" />
              </Form.Item>
            </>
          )}
        </div>
      )}
    </Form>
  );
};

export default ProfileForm;
export { ProfileAvatar };
