import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Card,
    Checkbox,
    Col,
    Input,
    List,
    Modal,
    Row,
    Table,
    Tag,
    Form,
    Spin,
    Pagination,
    Space,
    Select,
    Grid,
    Tooltip,
    Divider,
    Skeleton,
    Popconfirm,
    AutoComplete 
  } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useNotification from 'antd/es/notification/useNotification';
import { useSocket } from '../../utils/socketContext/socketProvider';
import { useAuth } from '../../utils/Auth/AuthWrapper';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import VirtualList from 'rc-virtual-list';
import { keywordAPI_addKeywordList, keywordAPI_addWord, keywordAPI_checkWordDuplicate, keywordAPI_deleteKeywordId, keywordAPI_deleteKeywordList, keywordAPI_editKeywordId, keywordAPI_getAll, keywordAPI_getModelList, keywordAPI_getResultModel, keywordAPI_getWord } from '../../services/keyword';
import { useRecoilState } from 'recoil';
import { localeState } from '../../utils/atoms';
moment.locale("en");



const KeywordsPage = (props) => {
    const [form] = Form.useForm();
    const [formListKeyword] = Form.useForm();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(false);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    Object.keys(screens).filter((item) => {
      if (screens[item] === false) {
        delete screens[item];
      }
    });

    const [listRowSelect,setListRowSelect] = useState(null)

    const [ismodal, setIsModal] = useState(false);
    const [ismodalKeyword, setIsModalKeyword] = useState(false);

    const [locale, setLocale] = useRecoilState(localeState)

    const [currentPageWord, setCurrentPageWord] = useState(0);
    const [totalWord, setTotalWord] = useState(0);
    const [pageSizeWord, setPageSizeWord] = useState(10);

    const [listModel,setListModel] = useState([])
    const [keyModel_id,setKeyModel_id] = useState(null)
    const [nameKeyword,setNameKeyword] = useState('')
    const [nameKeywordEn,setNameKeywordEn] = useState('')

    moment.locale(locale.locale)

    const columns = [
        {
          title: "Model Name",
          key: "model_name",
          dataIndex: "text",
          align: "center",
        },
        {
          title: "Actions",
          dataIndex: "",
          align: "center",
          key: "tags",
          render: (_, record) => (
            <Row gutter={16} justify={"center"}>
              <Col
                style={{ color: "#989898", cursor: "pointer" }}
                onClick={() =>{
                  getWord(record.model_id, record.model_name,true)
                  setKeyModel_id(record.model_id)
                  setNameKeyword(record.text)
                  setNameKeywordEn(record.model_name)
                  setIsModalKeyword(true)
                }}
              >
                 <Tooltip title="Edit" placement="top">
                  <EditOutlined className="icon primary" />
                 </Tooltip>
              </Col>
            </Row>
          ),
        },
      ];

      const handlePageChange = (page) => {
        setCurrentPage(page);
      };

      const isSameUser = (a, b) => a.model_id === b.model_id;

      const onlyInLeft = (left, right, compareFunction) => 
        left.filter(leftValue =>
        !right.some(rightValue => 
        compareFunction(leftValue, rightValue)));

      const getData = async() => {
        const res = await keywordAPI_getAll()
        setData(res.data)

        const resModelList = await keywordAPI_getModelList()
        const onlyInA = onlyInLeft(res.data, resModelList.data, isSameUser);
        const onlyInB = onlyInLeft(resModelList.data, res.data, isSameUser);

        const result = [...onlyInA, ...onlyInB];
        setListModel(resModelList.data)
        setListModel(result)

      }
      const [wordId,setWordId] = useState()

      const getWord = async(id,model_name,call_api) => {
        const data = {
          keyword_id: id,
          model_name: model_name,
          page: 1,
          call_api: call_api,
        }
        const res = await keywordAPI_getWord(data)
        setWordId(id)
        setDataList(res.data)
        setCurrentPageWord(res.meta.page)
        setTotalWord(res.meta.total)
        setPageSizeWord(res.meta.size)
      }

      const loadMoreData = async(id) => {
        const data = {
          keyword_id:id,
          page: currentPageWord + 1,
        }
        const res = await keywordAPI_getWord(data)
        setWordId(id)
        setDataList([...dataList,...res.data])
        setCurrentPageWord(res.meta.page)
        setTotalWord(res.meta.total)
        setPageSizeWord(res.meta.size)
      };

      useEffect(()=>{
        getData()
      },[])
      const [text,setText] = useState('')

      const data_listKeys = useMemo(()=>{
        const result = dataList
        return result
          
      },[dataList,text])

      const dataTable = useMemo(()=>{
        const result = data
        return result
      },[data])

      const [listItemsContainerRef, setListItemsContainerRef] = useState();
      const onlistItemsContainerRefChange = useCallback((node) => {
        if (node !== null) {
          setListItemsContainerRef(node);
        }
      }, []);

      const createKeywordTable = async(value) => {
        try{
          const data = {
            model_id:value.model_id
          }
          const res = await keywordAPI_addKeywordList(data)
          if(res.success){
            setIsModal(false)
            getData()
            form.resetFields()
          }
        }catch(e){
          console.error(e)
        }
      }

      const handleCreateWord = async(value) => {
        try{
          
          const data ={
            keymodel_id:keyModel_id,
            word:value.keyword
          }
          const res = await keywordAPI_addWord(data)
          if(res.success){
            getWord(keyModel_id,'',false)
            getData()
            formListKeyword.resetFields()
            setText('')
          }else{
            formListKeyword.setFields([
              {
                name: "keyword",
                errors: [res.message],
              },
            ])
          }
        }catch(e){
          console.error(e)
        }
      }

      const handleEditWord = async(value) => {
        try{
          
          const data ={
            keymodel_id:keyModel_id,
            id:listRowSelect,
            word:value.edit_keyword
          }
          const res = await keywordAPI_editKeywordId(data)
          if(res.success){
            getWord(keyModel_id,'',false)
            getData()
            setListRowSelect(null)
            formListKeyword.resetFields()
          }else{
            formListKeyword.setFields([
              {
                name: "edit_keyword",
                errors: [res.message],
              },
            ])
          }
        }catch(e){
          console.error(e)
        }
      }

      const handleGetResult = async() => {
        const res = await keywordAPI_getResultModel({model_id:keyModel_id,model_name:nameKeywordEn,keymodel_id:keyModel_id})
        setDataList([])
        formListKeyword.resetFields()
        setText('')
        setIsModalKeyword(false)
      }
      const [optionsaddword,setOptionsaddword] = useState([])
      const checkWordDuplicate = async(e) => {
        const data = {
          text: e,
          id:keyModel_id
        }
        const res = await keywordAPI_checkWordDuplicate(data)
        setOptionsaddword(res.data)

    }

  return (
    <div className="content-container">
        <Row style={{ justifyContent: "space-between" }} wrap>
        <Col>
            <h1 className="header-topic">{"Keyword"}</h1>
        </Col>
        </Row>
        <Row style={{ justifyContent: "flex-end" }} gutter={5}></Row>
        {/* table keywords */}
        <div className="table-scenario">
            <Table
                rowClassName="ScenarioTable"
                rowKey="keyword_id"
                columns={columns}
                dataSource={dataTable}
                loading={loading}
                pagination={false}
                scroll={{
                x: 1000,
                }}
            />
            <Row
                align="bottom"
                justify="end"
                style={{ margin: "10px 16px 0px 16px" }}
            >
                <Space
                direction="horizontal"
                align="center"
                style={{ justifyContent: "center" }}
                wrap
                >
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    showSizeChanger={false}
                    onChange={handlePageChange}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ margin: 0, fontWeight: 500 }}>
                    Rows per page :
                    </p>
                    <Select
                    onSelect={(value) => setPageSize(value)}
                    bordered={false}
                    style={{ width: "80px" }}
                    value={pageSize}
                    defaultValue={10}
                    options={[
                        { value: 10, label: 10 },
                        { value: 20, label: 20 },
                        { value: 30, label: 30 },
                        { value: 40, label: 40 },
                    ]}
                    ></Select>
                </div>
                </Space>
            </Row>
        </div>
        {/* modal create keyword list */}
        <Modal title="Add Keyword" open={ismodal} onOk={()=>form.submit()} onCancel={()=>setIsModal(false)}>
            <Form form={form} onFinish={createKeywordTable}>
                <Row justify={'center'}>
                  <Col sm={20}>
                    <Form.Item name="model_id" label="Model">
                      <Select options={listModel}/>
                    </Form.Item>
                  </Col>
                </Row>
            </Form>
        </Modal>
        {/* modal add words */}
        <Modal 
          title={nameKeyword}
          open={ismodalKeyword} 
          footer={<Button type='primary' onClick={()=>handleGetResult()}>OK</Button>} 
          closeIcon={true}
          width={600}
        >
          <Form form={formListKeyword} onFinish={listRowSelect == null? handleCreateWord:handleEditWord}>
            <Row justify={'center'}>
                <Col span={24}>
                    <Row justify={'center'} gutter={16}>
                        <Col span={18}>
                          <Form.Item name="keyword">
                            <AutoComplete  
                              onChange={(e)=>setText(e)} 
                              onSearch={(text) => checkWordDuplicate(text)}
                              options={optionsaddword}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                            <Button onClick={()=>formListKeyword.submit()}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                    <Divider style={{marginBottom:"-1.5rem",marginTop:"-0.1rem"}}/>
                    <Row  justify={'center'} style={{marginTop:"2rem"}}>
                        <Col span={22} style={{background:"white",height:"500px"}}>
                        <div
                          id="testkeywordscroll"
                          ref={onlistItemsContainerRefChange}
                          style={{
                            height: 500,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                          }}
                        >
                          {listItemsContainerRef && (
                            <InfiniteScroll
                            dataLength={data_listKeys.length}
                            next={()=>loadMoreData(keyModel_id)}
                            hasMore={data_listKeys.length < totalWord}
                            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                            endMessage={<></>}
                            scrollableTarget={listItemsContainerRef}
                            >
                              <List
                                  itemLayout="horizontal"
                                  style={{overflowX:"hidden"}}
                                  dataSource={data_listKeys}
                                  renderItem={(item, index) => (
                                  <List.Item key={item.id}>
                                      <List.Item.Meta
                                          key={item.id}
                                          title={
                                            <Row align={"middle"}>
                                          <Col >
                                            {listRowSelect == item.id ?
                                              <Form.Item name="edit_keyword" style={{width:"100%",margin:"0"}}>
                                                  <Input/>
                                              </Form.Item>
                                            :item.word
                                          }
                                            </Col>
                                          </Row>
                                          }
                                      />
                                      <Row gutter={16}>
                                          {listRowSelect == item.id ?
                                            <>
                                              <Col>
                                                <Button onClick={()=>setListRowSelect(null)}>
                                                  Cancel
                                                </Button>
                                              </Col>
                                              <Col>
                                                <Button onClick={()=> formListKeyword.submit()}>
                                                  OK
                                                </Button>
                                              </Col>
                                            </>
                                            :
                                            <>
                                              <Col>
                                            <span style={{color:"blue",cursor:"pointer"}} onClick={()=>{
                                              setListRowSelect(item.id)
                                              formListKeyword.setFieldsValue({edit_keyword:item.word})
                                            }}>
                                              Edit
                                            </span>
                                              </Col>
                                              <Col>
                                                <Popconfirm
                                                  title="Delete word"
                                                  description="Are you sure to delete this word?"
                                                  onConfirm={async()=>{
                                                    const res = await keywordAPI_deleteKeywordId({keyword_id:item.id})
                                                    if(res.success){
                                                      getWord(keyModel_id,'',false)
                                                      getData()
                                                    }
                                                  }}
                                                  onCancel={()=>{}}
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <span style={{color:"red",cursor:"pointer"}}>
                                                    Delete
                                                  </span>
                                                </Popconfirm>
                                              </Col>
                                            </>
                                          }
                                          
                                      </Row>
                                  </List.Item>
                                  )}
                              />
                            </InfiniteScroll>
                          )}
                         </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
          </Form>
        </Modal>
    </div>
  )
}

export default KeywordsPage