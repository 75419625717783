import React from "react";

export const Highlight = ({ children: text = "", tags = [] }: any) => {
  if (!tags?.length) return text;
  const matches = [...text.matchAll(new RegExp(tags.join("|"), "ig"))];

  const startText = text.slice(0, tags[0]?.being_char);
  return (
    <span>
      {startText}
      {tags.map((match: any, i: any) => {
        if (
          i != 0 &&
          tags[i]?.being_char > tags[i - 1]?.being_char &&
          tags[i]?.end_char < tags[i - 1]?.end_char
        ) {
          const startIndex = match.being_char;

          const endIndex = match.end_char;
          const nextIndex = tags[i + 1]?.being_char;
          const untilNextText = text.slice(endIndex, nextIndex);

          return <span key={i}>{untilNextText}</span>;
        } else {
          const startIndex = match.being_char;

          const endIndex = match.end_char;
          const nextIndex = tags[i + 1]?.being_char;
          const untilNextText = text.slice(endIndex, nextIndex);

          return (
            <span key={i}>
              <mark style={{ background: "#FFFBA4" }}>{match.token}</mark>
              {untilNextText}
            </span>
          );
        }
      })}
    </span>
  );
};
