import { Layout, Menu, Grid } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { getMenuSider } from "../config/menu/configMenu";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { pageSearch } from "../utils/atoms";

type Props = {
  collapsed: boolean;
  setCollapsed: any;
};

const SiderLayout = ({ collapsed, setCollapsed }: Props) => {
  const { Sider } = Layout;
  const { useBreakpoint } = Grid;
  const location = useLocation();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [currentPage, setCurrentPage] = useRecoilState(pageSearch);
  let menutest: any = [];

  const navigatePath = (data: any) => {
    if (
      data.keyPath[0] == "/video/myvideo" ||
      data.keyPath[0] == "/video/all"
    ) {
      setCurrentPage(1);
    }
    navigate(data.keyPath[0]);
  };

  const getMenu = async () => {
    const res = getMenuSider();
    menutest = res;
  };

  useEffect(() => {
    getMenu();
  }, []);

  useEffect(() => {
    if (screens.xl == false) {
      setCollapsed(true);
    }
  }, [screens]);

  const pathKey = location.pathname.split("/");
  return (
    <Sider
      className="box-shadow"
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Menu
        defaultSelectedKeys={[`${location.pathname}`]}
        selectedKeys={[`${location.pathname}`]}
        defaultOpenKeys={[`/${pathKey[1]}`]}
        mode="inline"
        theme="light"
        items={getMenuSider()}
        onClick={navigatePath}
      />
    </Sider>
  );
};

export default SiderLayout;
