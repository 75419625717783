import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  Select,
  Tag,
  message,
  Divider,
  Button,
  Space,
  ColorPicker,
  ColorPickerProps,
} from "antd";
import { translate } from "../../../../utils/libs/translate";
import { Color } from "antd/es/color-picker";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { addTag, getTagList, updateTask } from "../../../../services/task";
import { PlusOutlined } from "@ant-design/icons";
import { sendNotification } from "../../../../services/notification";
import { useAuth } from "../../../../utils/Auth/AuthWrapper";

type Props = {
  open: boolean;
  setOpen: any;
  form: any;
  getData: any;
};

interface TagItem {
  name: string;
  value: string;
  color: Color | string;
}

const ModalSetting = ({ open, setOpen, form, getData }: Props) => {
  const [items, setItems] = useState<TagItem[]>([]);
  const selectRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [color, setColor] = useState<Color | string>("#000");
  const [formatHex, setFormatHex] = useState<ColorPickerProps["format"]>("hex");
  const [messageApi, contextHolder] = message.useMessage();
  const [selectTag, setSelectTag] = useState<any>([]);
  const [openColor, setOpenColor] = useState(false);
  const { user } = useAuth();

  let index = 0;

  const getTagData = async () => {
    try {
      const res = await getTagList();
      if (res.status === 200) {
        var initialArray = res.result.map((item: any) => ({
          name: item.name,
          value: item.name,
          color: item.color,
        }));
        setItems(initialArray);
      }
    } catch (error) {}
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const color: any = items.filter((item) => {
      return item.value === value;
    });

    return (
      { color } && (
        <Tag
          color={color[0]?.color}
          style={{ marginRight: 3 }}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
        >
          {label}
        </Tag>
      )
    );
  };

  const handleSelectOpen = (open: any) => {
    setOpenColor(open);
  };

  const addTagData = async (data: any) => {
    try {
      const res = await addTag(data);
      return res;
    } catch (error) {
      return error;
    }
  };

  const onColorChange = (color: Color | string) => {
    setOpenColor(true);
    setColor(typeof color === "string" ? "string" : color.toHexString());
  };

  const addItem = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    var itemName = inputRef.current?.input.value;
    try {
      if (itemName !== "") {
        const res = await addTagData({ name: itemName, color: color });
        if (res.status === 200) {
          setItems([
            ...items,
            { name: itemName, value: itemName, color: color } ||
              `New item ${index++}`,
          ]);
          inputRef.current.input.value = null;
        } else {
          messageApi.error("Duplicated Tag!");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTagData();
  }, []);

  return (
    <>
      {contextHolder}
      <Modal
        title="Setting Video"
        open={open}
        onOk={async () => {
          let title = form.getFieldValue("name");
          await updateTask({
            task_id: form.getFieldValue("task_id"),
            title: title == "" ? null : title,
            tag: form.getFieldValue("tag"),
          });

          const res_noti = await sendNotification({
            content: `${
              user.user_name
            } ได้แก้ไขข้อมูลวิดีโอของ ${form.getFieldValue("name")}`,
            type: "update",
          });

          // getData();
          setOpen(false);
          setOpenColor(false);
        }}
        onCancel={() => {
          setOpen(false);
          setOpenColor(false);
        }}
        closeIcon={true}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Row justify={"center"}>
          <Col xs={20}>
            <Form form={form}>
              <Form.Item name="name" label="Title">
                <Input placeholder={translate("Title")} />
              </Form.Item>
              <Form.Item name="tag" label="Tag">
                <Select
                  onClick={() => setOpenColor(true)}
                  ref={selectRef}
                  mode="multiple"
                  onSelect={(item) => {
                    setSelectTag([...selectTag, item]);
                  }}
                  style={{ width: "100%" }}
                  autoFocus={true}
                  onDropdownVisibleChange={handleSelectOpen}
                  value={selectTag}
                  open={openColor}
                  placeholder={translate("Tag")}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input placeholder="Please enter tag" ref={inputRef} />
                        <ColorPicker
                          format={formatHex}
                          value={color}
                          onChange={onColorChange}
                          onFormatChange={setFormatHex}
                          placement="topRight"
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addItem}
                        >
                          Add item
                        </Button>
                      </Space>
                    </>
                  )}
                  options={items.map((item, index) => ({
                    key: index,
                    label: item.name,
                    value: item.value,
                  }))}
                  tagRender={tagRender}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalSetting;
