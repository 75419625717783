import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { useParams } from "react-router-dom";
import scrollIntoView from 'scroll-into-view';
import EvaluateVideo from "./component/Evaluate";
import { task_getEvaluate } from "../../../services/task";

const VideoDisplay = (props) => {
  const params = useParams();
  let myVideo = document.getElementById("video");
  const [indexSub, setIndexSub] = useState(0);
  const [iIndex, setI] = useState();
  const [evaluateOpen,setEvaluateOpen] = useState(false)
  const [formEvaluate] = Form.useForm()

  const timeUpdate = () => {
    try {
      if (myVideo && props.endTime != null) {
        if (myVideo.currentTime >= props.endTime) {
          myVideo.currentTime = props.seekTime;
        }
      }
      //
      if(myVideo !== null) {

        const st = props.allSub.map((item) => 
          Number(item.start_time / 1000)
      )
  
        const st2 = st.map((item) => item < 1.000 ? item = 0.000 : item = item)
      
        const et = props.allSub.map((item) => 
          Number(item.end_time / 1000))

  
        for(let i = indexSub; i < st2.length; i++){

          if(myVideo.currentTime >= st2[i] && myVideo.currentTime < et[i]){
            if(st2[i] == st2[i+1] || st2[i] == st2[i-1]){
              scrollIntoView(document.querySelector(`.scroll-${props.allSub[indexSub].sub_id}`), {
                align: {
                  top: 0,
                },
              });
              props.setFocusRow(`.scroll-${props.allSub[indexSub].sub_id}`)
              setI(indexSub);
            }else{
              if(props.focusRow == `.scroll-${props.allSub[i].sub_id}`){

              }else{
                scrollIntoView(document.querySelector(`.scroll-${props.allSub[i].sub_id}`), {
                  align: {
                    top: 0,
                  },
                });
              }
              props.setFocusRow(`.scroll-${props.allSub[i].sub_id}`)
              setI(i)
            }    
          }  
        }
      }
  
    } catch (e) {
      console.error(e);
    }
  };
  


  const getData = async() => {
    try{
      const res = await task_getEvaluate({task_id:params.id})
      if(res.success == true){
        formEvaluate.setFieldsValue({rate:res.data[0].rating,comment:res.data[0].comment})
      }
    }catch(e){
      console.error(e)
    }
  }

  useEffect(()=>{
    getData()
  },[])

  return (
    <Col md={24} lg={12}>
      {props.header()}
      <EvaluateVideo evaluateOpen={evaluateOpen} setEvaluateOpen={setEvaluateOpen} form={formEvaluate} task_id={params.id}/>
      <Row className="tests1">
        <Col xs={24} style={{ maxHeight: "580px" }}>
          <video
            id="video"
            preload="metadata"
            width="100%"
            height="100%"
            controls
            onTimeUpdate={timeUpdate}
          >
            <source
              src={`${process.env.REACT_APP_VIDEO_PATH}${params.id}`}
              // src={`/screenshots/lakorn_ch7.mp4`}
              type="video/mp4"
            />
            <track
              label="Thai"
              kind="subtitles"
              src={props.subtitle}
              default
            />
          </video>
        </Col>
      </Row>
      <Row style={{marginTop:"1rem"}} justify={"end"}>
        <Button type={"primary"} onClick={()=>setEvaluateOpen(true)}>Evaluate</Button>
      </Row>
    </Col>
  );
};

export default VideoDisplay;
