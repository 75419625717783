import PropTypes from 'prop-types';
import { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Card, Typography, Space } from 'antd'
import { chartConfig } from '../index'
import { UserOutlined, VideoCameraOutlined, ClockCircleOutlined, SecurityScanOutlined, TeamOutlined } from '@ant-design/icons'
import '../style/StatusCard.less'
import { translate } from '../../../utils/libs/translate';
import { pieData,cateData,files,getRandomTime } from '../sample/usageData';
import RiskDetectChart from './RiskDetectChart';
function StatusCard({ sidePrefix, view, data }) {
    const { Title } = Typography;

    return (
        <Card className='status-card' >
            <Space direction='vertical' className='w-full h-full'>
                <Space direction='horizontal' className='w-full h-full'>
                    <div className='status-head-text'>{sidePrefix !== "Super" ? translate(sidePrefix + " Status ") : translate("Status ")}{sidePrefix === 'Your' ?
                        <UserOutlined className='icon' />
                        : (sidePrefix === 'Team') ? <TeamOutlined className='icon' /> : null}
                    </div>
                </Space>
                <div className={view !== "User" && sidePrefix === "Team" ? 'grid-container col-8' : "grid-container"}>
                    <div className={view === "SuperAdmin" ? 'col-1-super' : "col-1"}>
                        <Card title={<div className='card-head-text'><VideoCameraOutlined className='mr-5' />{translate("Video")}</div>} className='item-card' size='small'>
                            <Title level={2} className='header-text'>{data&&data ? data.file_count : 0}</Title>
                            <Title level={5} className='content-text'>{translate("Files")}</Title>
                        </Card>
                    </div>
                    <div className={view === "SuperAdmin" ? 'col-2-super' : "col-2"}>
                        <Card title={<div className='card-head-text'><ClockCircleOutlined className='mr-5' />{translate("Duration")}</div>} className='item-card' size='small'>
                            <Title level={2} className='header-text'>{data ? data.duration_count:0}</Title>
                            <Title level={5} className='content-text'>{translate("Hr Min Sec")}</Title>
                        </Card>
                    </div>

                    {(view !== 'SuperAdmin') ?
                        <div className={(view === "Admin" && sidePrefix === "Team") ? "col-3-admin" : "col-3"} >
                            <Card className='pie-card h-full' title={<div className='card-head-text'><SecurityScanOutlined className='mr-5' />{translate("Risk Detection")}</div>} size='small'>
                                <RiskDetectChart chartId={sidePrefix} data={data? data.detection:pieData}/>
                            </Card>
                        </div>
                        :
                        null
                    
                    }
                    {(view !== "User" && sidePrefix === "Team") &&
                        <div className='col-4-admin'>
                            <Card className='pie-card h-full w-full' title={<div className='card-head-text'><SecurityScanOutlined className='mr-5' />{translate("Scenario Detected")}</div>} size='small'>
                                <div id={"Scenario Detected"} className='w-full h-auto'/>
                                <RiskDetectChart chartId={"Scenario Detected"} data={data ? data.scenario_detection :cateData}/>
                            </Card>
                        </div>
                    }
                </div>
            </Space>
        </Card>
    )
}

StatusCard.propTypes = {
    sidePrefix: PropTypes.string
};

export default StatusCard