import { Col, Empty, Image, Row, Space, Tag } from "antd";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  task_getCountModels,
  task_getDataModels,
} from "../../../../services/task";
import DrawAD_len from "./drawAD_len";

type Props = {
  dataModel: any;
  dataScenario: any;
  setSeekTime: (res: any) => any;
  setEndTime: (res: any) => any;
  selectScene: any;
  setSelectScene: (res: any) => any;
  reloadScene: boolean;
  selectModelScene: any;
  setSelectModelScene: any;
  dataTask: any;
};

const Scene = ({
  dataModel,
  dataScenario,
  setSeekTime,
  setEndTime,
  selectScene,
  setSelectScene,
  reloadScene,
  selectModelScene,
  setSelectModelScene,
  dataTask,
}: Props) => {
  let myVideo: any = document.getElementById("video");
  const [dataScenario2, setDataScenario2] = useState<any>([]);
  const params = useParams();

  const SecToTime = (timeInSeconds: any) => {
    let time: any = parseFloat(timeInSeconds).toFixed(3);
    let hours = Math.floor(time / 60 / 60);
    let minutes = Math.floor(time / 60) % 60;
    let seconds = Math.floor(time - minutes * 60);
    let milliseconds = time.slice(-3);

    return (
      pad(hours, 2) +
      ":" +
      pad(minutes, 2) +
      ":" +
      pad(seconds, 2) +
      "." +
      pad(milliseconds, 3)
    );
  };

  const pad = (num: any, size: any) => {
    return ("000" + num).slice(size * -1);
  };

  const dataModelScene = dataModel.filter(
    (i: any) => i.model_type == "b8750eec-0492-11ee-a8f8-0242ac1b0005"
  );

  let alldata: any = [];
  dataScenario[0]?.scene_task.map((i: any) => {
    alldata.push(i);
  });

  const tagObject = alldata.reduce((group: any, product: any) => {
    const { scene } = product;
    group[scene] = group[scene] ?? [];
    group[scene].push({ value: "" });
    return group;
  }, {});

  const resultTag = dataModelScene.map((d: any) => {
    if (tagObject[d.model_name] != undefined) {
      return { name: d.text, value: tagObject[d.model_name].length };
    } else {
      return null;
    }
  });

  const getData = async (keyword?: any) => {
    try {
      const res = await task_getDataModels({
        task_id: params.id,
        model: keyword,
      });
      setDataScenario2(res.length == 0 ? res : res[0].data);
    } catch (e) {
      console.error("err:", e);
    }
  };

  useEffect(() => {
    getData();
    getCountModelTags();
  }, [reloadScene]);

  const [countTagModels, setCountTagModels] = useState<any>({});

  const getCountModelTags = async () => {
    try {
      let selectTag = selectModelScene;
      const res = await task_getCountModels({
        task_id: params.id,
        type: "scene_task",
      });

      setCountTagModels(res.counts);
      dataModelScene.map((d: any) => {
        if (res.counts[d.model_name] == undefined) {
          return 0;
        } else if (res.counts[d.model_name] == 0 && selectTag.length == 0) {
        } else if (res.counts[d.model_name] > 0 && selectTag.length == 0) {
          selectTag = d.model_name;
          setSelectModelScene(d.model_name);
        } else {
          return 0;
        }
      });
      getDataModel(selectTag);
    } catch (e) {
      console.error(e);
    }
  };

  const getDataModel = async (selectTag: any) => {
    const res = await task_getDataModels({
      task_id: params.id,
      model: selectModelScene == "" ? selectTag : selectModelScene,
    });
    setDataScenario2(res.length == 0 ? res : res[0].data);
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col xs={24} style={{ minWidth: "100%" }}>
        <Space size={[0, 8]} wrap style={{ minWidth: "100%" }}>
          {dataModelScene.map((d: any) => {
            return (
              <Tag
                key={d.model_id}
                color={selectModelScene != d.model_name ? "default" : "#55acee"}
                bordered={false}
                className={
                  selectModelScene.length == 0
                    ? "video-tag-selected"
                    : selectModelScene != d.model_name
                    ? "video-tag-unselected"
                    : "video-tag-selected"
                }
                style={{ cursor: "pointer", borderWidth: "4px" }}
                onClick={() => {
                  getData(d.model_name);
                  setSelectModelScene(d.model_name);
                  setSelectScene(null);
                }}
              >
                {d.text} (
                {countTagModels && countTagModels[d.model_name] == undefined
                  ? 0
                  : countTagModels[d.model_name]}
                )
              </Tag>
            );
          })}
        </Space>
      </Col>
      <Col
        xs={24}
        style={{
          marginTop: "10px",
          height: "370px",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        <Row gutter={[16, 16]} style={{ minWidth: "100%", padding: "10px" }}>
          {dataScenario2 &&
            dataScenario2.map((d: any, ind: number) => {
              return (
                <Col
                  key={ind}
                  flex="0 1 33%"
                  style={
                    selectScene == ind
                      ? {
                          border: "solid #4096ff 2px",
                          padding: "5px",

                          width: "100%",
                        }
                      : {
                          width: "100%",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                        }
                  }
                  onClick={() => {
                    setSeekTime(Number(d.begin_time / 1000));
                    setEndTime(Number(d.ending_time / 1000));
                    if (myVideo) {
                      myVideo.currentTime = Number(d.begin_time / 1000);
                    }
                    setSelectScene(ind);
                  }}
                >
                  <Row>
                    {d.screenshot == undefined ? (
                      <DrawAD_len
                        ind={ind}
                        settime={d.begin_time}
                        screenshot={
                          process.env.REACT_APP_IMG_PATH +
                          `/memo/results/${params.id}/image_shot/` +
                          d.screenshot
                        }
                      />
                    ) : (
                      <Image
                        src={
                          process.env.REACT_APP_IMG_PATH +
                          `/memo/results/${params.id}/image_shot/` +
                          d.screenshot
                        }
                        // src={`/screenshots/${d.screenshot}`}
                        preview={false}
                        width={"100%"}
                        height={"auto"}
                      ></Image>
                    )}
                  </Row>
                  <Row justify="space-between" style={{ marginTop: "5px" }}>
                    <Col>
                      {SecToTime(
                        (Number(d.ending_time) - Number(d.begin_time)) / 1000
                      )}
                    </Col>
                    <Col>{Number(d.score * 100).toFixed(2)} %</Col>
                  </Row>
                </Col>
              );
            })}
          {dataScenario2?.length == 0 && (
            <Col xs={24} style={{ minWidth: "100%" }}>
              <Empty />
            </Col>
          )}
          {dataModelScene.map((d: any) => {
            if (d.model_name == "ad_len") {
              return (
                <Col offset={1} xs={22} style={{ marginTop: "3rem" }}>
                  <Row>
                    <Col xs={8}>
                      <Row
                        justify={"center"}
                        style={{ background: "#FBF6FF", padding: "16px 0" }}
                      >
                        ระยะเวลาของวิดีโอ (นาที)
                      </Row>
                      <Row
                        justify={"center"}
                        style={{ background: "white", padding: "16px 0" }}
                      >
                        {new Date(
                          dataScenario[0].ad_len_task.length == 0
                            ? 0
                            : dataScenario[0].ad_len_task[0].total_vdo_len_min
                            ? Number(
                                dataScenario[0].ad_len_task[0].total_vdo_len_min
                              )
                            : 0
                        )
                          .toISOString()
                          .slice(11, 19)}
                      </Row>
                    </Col>
                    <Col xs={8}>
                      <Row
                        justify={"center"}
                        style={{ background: "#FBF6FF", padding: "16px 0" }}
                      >
                        ระยะเวลาโฆษณา (นาที)
                      </Row>
                      <Row
                        justify={"center"}
                        style={{
                          background: "white",
                          padding: "16px 0",
                          color: "red",
                        }}
                      >
                        {new Date(
                          dataScenario[0].ad_len_task.length == 0
                            ? 0
                            : dataScenario[0].ad_len_task[0].total_ad_len_min
                            ? Number(
                                dataScenario[0].ad_len_task[0].total_ad_len_min
                              )
                            : 0
                        )
                          .toISOString()
                          .slice(11, 19)}
                      </Row>
                    </Col>
                    <Col xs={8}>
                      <Row
                        justify={"center"}
                        style={{ background: "#FBF6FF", padding: "16px 0" }}
                      >
                        ค่าเฉลี่ยต่อชั่วโมง (นาที)
                      </Row>
                      <Row
                        justify={"center"}
                        style={{
                          background: "white",
                          padding: "16px 0",
                          color: "red",
                        }}
                      >
                        {new Date(
                          dataScenario[0].ad_len_task.length == 0
                            ? 0
                            : dataScenario[0].ad_len_task[0].avg_ad_len_min
                            ? Number(
                                dataScenario[0].ad_len_task[0].avg_ad_len_min
                              )
                            : 0
                        )
                          .toISOString()
                          .slice(11, 19)}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            }
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default Scene;
