import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

const DrawAD_len = (props) => {
  const params = useParams()
    const [data,setData] = useState()

    useEffect(() => {
        getData()
      }, [props.settime])
    
    const getData = () => {
       const video = document.getElementById(`video${props.ind}`)
       video.currentTime = Number(props.settime/1000)
    }

    const newData = useMemo(()=>{
      const res = data
      return res
    },[data])

  return (
   <>
    <video
      id={`video${props.ind}`}
      preload="metadata"
      width="100%"
      height="100%"
    >
      <source
      src={`${process.env.REACT_APP_VIDEO_PATH}${params.id}`}
      // src={props.screenshot}
      type="video/mp4"
      onLoad={()=>""}
      />
    </video>
   </>
  )
}

export default DrawAD_len