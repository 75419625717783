import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Tag,
  Grid,
} from "antd";
import "./styles/index.less";
import { EditOutlined } from "@ant-design/icons";
import { get_ScenarioByID, put_ScenarioAPI } from "../../../services/scenario";
import { useNavigate, useParams } from "react-router-dom";
import { selectedAllModel, selectedModel } from "../../../services/model";
import { useRecoilState } from "recoil";
import { localeState } from "../../../utils/atoms";
import { t } from "i18next";
import moment from "moment";

type Props = {};

const EditTable = (props: Props) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const params = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [titleName, setTitleName] = useState<any>("");
  const [newName, setNewName] = useState<any>("");
  const [topic, setTopic]: any = useState<any>([]);
  const [object, setObject]: any = useState([]);
  const [scene, setScene]: any = useState([]);
  const [editName, setEditName]: any = useState(false);
  const screens: any = Grid.useBreakpoint();
  Object.keys(screens).filter((item) => {
    if (screens[item] === false) {
      delete screens[item];
    }
  });

  const getData = async () => {
    try {
      let text: any = [];
      let object: any = [];
      let scene: any = [];
      let textModel: any = [];
      let objectModel: any = [];
      let sceneModel: any = [];
      const res = await get_ScenarioByID({ id: params.id });
      const resAllModel = await selectedAllModel();

      if (res.status == 200) {
        resAllModel.data.map((d: any) => {
          if (d.model == "text") {
            text.push(d);
          } else if (d.model == "object") {
            object.push(d);
          } else if (d.model == "scene") {
            scene.push(d);
          }
        });
        res.result.model.map((d: any) => {
          if (d.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005") {
            textModel.push(d);
          } else if (d.model_type == "b875130b-0492-11ee-a8f8-0242ac1b0005") {
            objectModel.push(d);
          } else if (d.model_type == "b8750eec-0492-11ee-a8f8-0242ac1b0005") {
            sceneModel.push(d);
          }
        });
        sortData(text);
        sortData(object);
        sortData(scene);
        const checkObj = await checkDataSelect(object, objectModel);
        const checkScene = await checkDataSelect(scene, sceneModel);
        const checkText = await checkDataSelect(text, textModel);

        setTopic(checkText);
        setObject(checkObj);
        setScene(checkScene);
        setTitleName(res.result.title);
      } else if (res.error) {
        navigate("/404");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const sortData = async (data: any) => {
    return data.sort(
      (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix()
    );
  };

  const checkDataSelect = async (data: any, checkData: any) => {
    const result = data.map((d: any) => {
      const res = checkData.filter((v: any) => {
        if (v.model_id == d.model_id) {
          return true;
        }
        return false;
      });

      if (res.length > 0) {
        return { ...d, check: true };
      } else {
        return { ...d, check: false };
      }
    });
    return result;
  };

  const handleCheckModel = async (
    check: boolean,
    data: any,
    modelName: string
  ) => {
    try {
      if (modelName == "topic") {
        const result = topic.map((d: any) => {
          if (d.model_id == data.model_id) {
            return { ...d, check: check };
          }
          return d;
        });
        setTopic(result);
      } else if (modelName == "object") {
        const result = object.map((d: any) => {
          if (d.model_id == data.model_id) {
            return { ...d, check: check };
          }
          return d;
        });
        setObject(result);
      } else if (modelName == "scene") {
        const result = scene.map((d: any) => {
          if (d.model_id == data.model_id) {
            return { ...d, check: check };
          }
          return d;
        });
        setScene(result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const confirm_checkSelectData = (data: any) => {
    const checktrue = data.filter((d: any) => {
      if (d.check == true) {
        return d;
      }
    });
    const res = checktrue.map((d: any) => {
      return {
        model_id: d.model_id,
        model_type: d.model_type,
        model_name: d.model_name,
        text: d.text,
      };
    });
    return res;
  };

  const handleSubmit = async () => {
    try {
      const data = {
        id: params.id,
        title: titleName,
        data: [
          ...confirm_checkSelectData(object),
          ...confirm_checkSelectData(scene),
          ...confirm_checkSelectData(topic),
        ],
      };

      const res = await put_ScenarioAPI(data);
      if (res.status === 200) {
        navigate("/setting/settingSce");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="content-container">
      <Form form={form}>
        <Row align="middle" gutter={16}>
          <Col>
            <h1 className="header-topic">
              {t("Scenario")}:{/* {scenario} */}
            </h1>
          </Col>
          <Col>
            {editName ? (
              // <Form.Item name="name">
              <Input
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
            ) : (
              // </Form.Item>
              <h2>{titleName}</h2>
            )}
          </Col>
          <Col>
            {editName ? (
              <Row gutter={16}>
                <Col style={{ cursor: "pointer" }}>
                  <Button
                    onClick={() => {
                      setTitleName(titleName);
                      setEditName(!editName);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                </Col>
                <Col style={{ cursor: "pointer" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setTitleName(newName);
                      setEditName(!editName);
                    }}
                  >
                    {t("Save")}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row
                style={{ cursor: "pointer" }}
                onClick={() => {
                  form.setFieldsValue({ name: titleName });
                  setNewName(titleName);
                  setEditName(!editName);
                }}
              >
                <EditOutlined className="icon primary" />
              </Row>
            )}
          </Col>
        </Row>
      </Form>

      <Row gutter={[16, 16]} justify="space-around" className="card-model">
        <Col xs={24} sm={24} md={7}>
          <Card title={t("Text")} bordered={false}>
            <div id="scrollableDiv" className="scrollModel ">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={topic}
                style={{ height: "250px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left">
                        <Checkbox
                          // value={item.text}
                          checked={item.check}
                          onClick={(e: any) => {
                            handleCheckModel(e.target.checked, item, "topic");
                          }}
                          style={{
                            color: "#E04D8B",
                            padding: "0px",
                            // marginLeft: "50%",
                          }}
                          // size="medium"
                        />
                      </div>

                      <div className="right">
                        <Tag color="magenta" className="editScenario">
                          {item.text}
                        </Tag>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={7}>
          <Card title={t("Object")} bordered={false}>
            <div id="scrollableDiv" className="scrollModel ">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={object}
                style={{ minHeight: "250px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left">
                        <Checkbox
                          checked={item.check}
                          onClick={(e: any) => {
                            handleCheckModel(e.target.checked, item, "object");
                          }}
                          style={{
                            color: "#218AE7",
                            padding: "0px",
                          }}
                        />
                      </div>
                      <div className="right">
                        <Tag color="blue" className="editScenario">
                          {item.text}
                        </Tag>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={7}>
          <Card title={t("Scene")} bordered={false}>
            <div id="scrollableDiv" className="scrollModel ">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={scene}
                style={{ height: "250px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left">
                        <Checkbox
                          checked={item.check}
                          onClick={(e: any) => {
                            handleCheckModel(e.target.checked, item, "scene");
                          }}
                          style={{
                            color: "#42a219",
                            padding: "0px",
                          }}
                        />
                      </div>
                      <div className="right">
                        <Tag color="green" className="editScenario">
                          {item.text}
                        </Tag>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row
        justify={"end"}
        gutter={10}
        style={{ marginRight: "24px", marginTop: "10px" }}
      >
        <Col>
          <Button onClick={() => navigate(-1)}>{t("Back")}</Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("Confirm")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default EditTable;
