import { Table, Col, Input, Button, Modal, Select, Form, Pagination, Space, Row } from 'antd'
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import "./index.less"
import { useEffect, useState } from 'react'
import { useAuth } from '../../utils/Auth/AuthWrapper'
import { roleConfig } from '../../config/config'
import { edit_profile } from '../../services/user'
import { add_member, create_org, edit_org, org_getAll, org_getMember } from '../../services/organization'
import { user_register } from '../../services/user'
import UserColumns from './user/userColumn'
import OrgColumns from './organization/OrgColumns'
import { translate } from '../../utils/libs/translate'
import useNotification from '../../components/Notification'
import { t } from 'i18next'
import FormManage from './FormManage'
import { useSocket } from "../../utils/socketContext/socketProvider";

function ManageTable({ side }) {
    //!Const
    const {socket} = useSocket();
    const auth = useAuth()
    const USER = auth.user
    const { confirm } = Modal
    const [submitting, setSubmitting] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [modalState, setModalState] = useState("Add")
    const [socketData, setSocketData] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [search_query, setSearchQuery] = useState()
    const [pageSize, setPageSize] = useState(10)
    const [form] = Form.useForm();

    const { openNotification, contextHolder } = useNotification();

    const getData = async () => {
        const data = {
            page: currentPage,
            size: pageSize,
            order_by: [],
            query: search_query,
        };
        try {
            setLoading(true)
            let res = side === 'User' ? await org_getMember(data) : await org_getAll(data)
            if (res.status === 200 && res?.result) {
                if (res?.result.length === 0) {
                    setCurrentPage(1)
                }

                setLoading(false)
                setTotal(res.total)
                setData(res.result)
            } else {
                errorNotification()
            }
        } catch (err) {
            errorNotification()
            console.error(err);
        }
    }

    useEffect(() => {
        getData()
    }, [currentPage, search_query, pageSize])

    useEffect(() => {
        socket.emit("join_room", auth.user.role === "SuperAdmin" ? "super" : auth.user.org_name);
    }, [])

    useEffect(() => {
        if(side === 'User') {
            socket.on("manage_user_update", () => {
                setSocketData((prev) => prev = true)
            });
        }else{
            socket.on("manage_org_update", () => {
                setSocketData((prev) => prev = true)
            })
        }
    }, [socket])

    useEffect(() => {
        if (socketData) {
            getData()
            setSocketData(prev => prev = false)
        }
    }, [socketData])

    const onSearch = async (value) => {
        setSearchQuery(value)
        setCurrentPage(1)
    }

    const upDateSocket = (org_name) => {
        if (side === "User") {
            auth.user.role === 'SuperAdmin' ? socket.emit("update_manage_user", { room: org_name })
                : socket.emit("update_manage_user", { room: auth.user.org_name });
        } else {
            socket.emit("update_manage_org", { room: "super" });
        }
    }

    const handleSwitchChange = async (checked, record) => {
        try {
            let res
            let data = {}
            checked ? data.status = 1 : data.status = 0
            if (side === 'User') {
                data.member_id = record.user_id
                res = await edit_profile(data)
            } else {
                data.org_id = record.org_id
                res = await edit_org(data)
            }

            if (res.status === 200) {
                //แก้ขัด
                getData()
                //
                upDateSocket(record.org_name)
            } else {
                openNotification('error', "Cannot change status")
            }
        } catch (error) {
            openNotification('error', "Cannot change status")
        }
    };

    const cancel = (e) => {
    }

    const deleteConfirm = async (record) => {
        confirm({
            title: translate("Are you sure to delete this user?"),
            icon: <ExclamationCircleFilled className='' />,
            okText: translate("Yes"),
            okType: "danger",
            cancelText: translate("No"),
            onOk: async () => {
                try {
                    const res = await edit_profile({ status: 2, member_id: record.user_id });
                    if (res.status === 200 || res.status === "200") {
                        openNotification('success', "Delete successfully")
                        //แก้ขัด
                        getData()
                        //
                        upDateSocket(record.org_name)
                    } else {
                        errorNotification()
                    }
                } catch (e) {
                    errorNotification()
                }
            },
            onCancel() {
            },
        });
    };

    const showModal = () => {
        form.resetFields();
        setIsModalOpen(true);
    };

    const errorNotification = () => {
        openNotification("error", 'Cannot get data ! please try again')
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setModalState("Add")
    };

    const openEdit = () => {
        setModalState("Edit")
        showModal()
    }

    const addUser = async (data) => {
        data.acc_type = "individual"

        //on admin when add user gonna use admin org id
        if (data?.org_name === undefined) {
            data.org_name = auth.user.org_id
        }
        try {
            const res = auth.user.role === 'Admin' ? await user_register(data) : await add_member(data)
            return res
        } catch (err) {
            return 0
        }
    }

    const editUser = (user) => {
        openEdit()
        form.setFieldsValue({
            member_id: user.user_id,
            org_name: user.org_name,
            user_name: user.user_name,
            password: null,
            role_name: user.role_name,
        })
    }

    const editOrganize = (org) => {
        openEdit()
        form.setFieldsValue({
            org_id: org.org_id,
            org_name: org.name
        })
    }

    const onFinish = async (values) => {
        let res = null
        try {
            if (side === 'User') {
                if (modalState === 'Add') {
                    res = await addUser(values)
                } else {
                    res = await edit_profile(values)
                }
            }
            if (side === 'Organization') {
                if (modalState === 'Add') {
                    res = await create_org(values)
                } else {
                    res = await edit_org(values)
                }
            }

        } catch (error) {
            setSubmitting(false)
            errorNotification()
        }

        if (res?.status === 200 || res?.status === 1) {
            setSubmitting(false)
            setIsModalOpen(false);
            openNotification('success', `${modalState} ${side} success`)
            setModalState("Add")
            //แก้ขัด
            getData()
            //
            upDateSocket(values.org_name)
        } else {
            if (side === 'User') {
                form.setFields([
                    {
                        name: "user_email",
                        errors: [res?.message],
                    },
                ]);
            } else {
                form.setFields([
                    {
                        name: "org_name",
                        errors: [res?.message],
                    },
                ]);
            }
            setSubmitting(false)
        }
    }
    const [sortedInfo, setSortedInfo] = useState({});
    //COLUMNS
    const userColumns = UserColumns({ USER, deleteConfirm, editUser, handleSwitchChange, cancel, sortedInfo })
    const orgColumns = OrgColumns({ handleSwitchChange, editOrganize, cancel })

    const handleSearchChange = (pagination, filters, sorter) =>{
        setSortedInfo(sorter);
    }

    return (
        <div className="content-container">
            {contextHolder}
            <FormManage
                UserData={USER}
                form={form}
                modalState={modalState}
                side={side}
                isModalOpen={isModalOpen}
                onFinish={onFinish}
                handleCancel={handleCancel}
                submitting={submitting}
            />
            <Row className='justify-between'>
                <Col>
                    <h1 className='header-topic'>{translate(side)}</h1>
                </Col>
                <Col className="input-group flex justify-between">
                    <div className="menu-group">
                        <Input.Search
                            className='input-search'
                            placeholder={translate("Search")}
                            onSearch={onSearch}
                            allowClear
                        />
                        <Button type="primary" className='table-add-btn' onClick={showModal}><span><PlusOutlined /> {translate("Add")}</span></Button>
                    </div>
                </Col>
            </Row>
            <Row >
                <Table
                    style={{ width: '100%' }}
                    className='manageTable'
                    rowKey={side === "User" ? "user_id" : "org_id"}
                    columns={side === "User" ? userColumns : orgColumns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ x: 1000, }}
                    pagination={false}
                    // onChange={handleSearchChange}
                />
            </Row>
            {total > 0 &&
                <Row
                    align="bottom"
                    justify="space-between"
                    style={{ width: '100%', marginTop: "10px" }}
                >
                    <h1>{t("Total")} {total} {total > 1 ? side === "User" ? t('Users') : t("Organizations") : side === "User" ? t('User') : t("Organization")}</h1>
                    <Space direction="horizontal" align="center">
                        <Pagination
                            className="video-pagination"
                            total={total}
                            showSizeChanger={false}
                            pageSize={pageSize}
                            current={currentPage}
                            onChange={(page) => setCurrentPage(page)}
                        />
                        <p style={{ margin: 0, fontWeight: 500 }}>{t("Rows per page ")}:</p>
                        <Select
                            onSelect={(size) => setPageSize(size)}
                            bordered={false}
                            style={{ width: "80px" }}
                            value={pageSize}
                            defaultValue={10}
                            options={[
                                { value: 10, label: 10 },
                                { value: 20, label: 20 },
                                { value: 30, label: 30 },
                                { value: 40, label: 40 },
                            ]}
                        ></Select>
                    </Space>
                </Row>
            }
        </div>
    )
}

export default ManageTable
