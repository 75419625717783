import { DownOutlined, SwapOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Image,
  MenuProps,
  Row,
  Space,
  Tag,
} from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

type Props = {
  dataModel: any;
  dataScenario: any;
  setSeekTime: (res: any) => any;
  setEndTime: (res: any) => any;
  selectScene: any;
  setSelectScene: (res: any) => any;
  reloadScene: boolean;
  selectModelScene: any;
  setSelectModelScene: any;
};

const Scene = ({
  dataModel,
  dataScenario,
  setSeekTime,
  setEndTime,
  selectScene,
  setSelectScene,
  reloadScene,
  selectModelScene,
  setSelectModelScene,
}: Props) => {
  let myVideo: any = document.getElementById("video");
  const [dataScenario2, setDataScenario2] = useState<any>([]);
  const params = useParams();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item
        </a>
      ),
    },
  ];

  const SecToTime = (timeInSeconds: any) => {
    let time: any = parseFloat(timeInSeconds).toFixed(3);
    let hours = Math.floor(time / 60 / 60);
    let minutes = Math.floor(time / 60) % 60;
    let seconds = Math.floor(time - minutes * 60);
    let milliseconds = time.slice(-3);

    return (
      pad(hours, 2) +
      ":" +
      pad(minutes, 2) +
      ":" +
      pad(seconds, 2) +
      "." +
      pad(milliseconds, 3)
    );
  };
  const pad = (num: any, size: any) => {
    return ("000" + num).slice(size * -1);
  };

  const dataModelScene = dataModel.filter(
    (i: any) => i.model_type == "b8750eec-0492-11ee-a8f8-0242ac1b0005"
  );

  let alldata: any = [];
  dataScenario[0].scene_task.map((i: any) => {
    alldata.push(i);
    // });
  });
  const tagObject = alldata.reduce((group: any, product: any) => {
    const { scene } = product;
    group[scene] = group[scene] ?? [];
    group[scene].push(product);
    return group;
  }, {});
  const getData = (keyword?: any) => {
    const res = dataScenario[0].scene_task.filter((item: any) => {
      return item.scene == keyword;
    });
    setDataScenario2(keyword == undefined ? dataScenario[0].scene_task : res);
  };

  useEffect(() => {
    getData();
  }, [reloadScene]);

  return (
    <Row style={{ width: "100%" }}>
      <Col xs={24} style={{ minWidth: "100%" }}>
        <Space size={[0, 8]} wrap style={{ minWidth: "100%" }}>
          {dataModelScene.map((d: any) => {
            return (
              <Tag
                key={d.model_id}
                color={selectModelScene != d.model_name ? d.color : "#c41d52"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  getData(d.model_name);
                  setSelectModelScene(d.model_name);
                }}
              >
                {d.text} (
                {tagObject[d.model_name] == undefined
                  ? 0
                  : tagObject[d.model_name].length}
                )
              </Tag>
            );
          })}
        </Space>
      </Col>

      <Col
        xs={24}
        style={{
          marginTop: "10px",
          height: "370px",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        <Row gutter={[16, 16]} style={{ minWidth: "100%" }}>
          {dataScenario2 &&
            dataScenario2.map((d: any, ind: number) => {
              return (
                <Col
                  key={ind}
                  flex="0 1 33%"
                  style={
                    selectScene == d.screenshot
                      ? {
                          border: "solid #4096ff 2px",
                          padding: "5px",

                          width: "100%",
                        }
                      : {
                          width: "100%",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                        }
                  }
                  onClick={() => {
                    setSeekTime(Number(d.begin_time / 1000));
                    setEndTime(Number(d.ending_time / 1000));
                    if (myVideo) {
                      myVideo.currentTime = Number(d.begin_time / 1000);
                    }
                    setSelectScene(d.screenshot);
                  }}
                >
                  <Row>
                    <Image
                      // src={d.path}
                      // src={"/screenshots/" + d.screenshot}
                      src={
                        process.env.REACT_APP_BASE_PATH +
                        "/screenshots/" +
                        d.screenshot
                      }
                      preview={false}
                      width={"100%"}
                      height={"auto"}
                    ></Image>
                  </Row>
                  <Row justify="space-between" style={{ marginTop: "5px" }}>
                    <Col>
                      {SecToTime(
                        Number(d.ending_time / 1000) -
                          Number(d.begin_time / 1000)
                      )}
                    </Col>
                    <Col>{Number(d.score * 100).toFixed(2)} %</Col>
                  </Row>
                </Col>
              );
            })}
          {dataScenario2.length == 0 && (
            <Col xs={24} style={{ minWidth: "100%" }}>
              <Empty />
            </Col>
          )}
          {dataModelScene.map((d: any) => {
            if (d.model_name == "ads-duration") {
              return (
                <Col offset={1} xs={22} style={{ marginTop: "3rem" }}>
                  <Row>
                    <Col xs={12}>
                      <Row
                        justify={"center"}
                        style={{ background: "#FBF6FF", padding: "16px 0" }}
                      >
                        ระยะเวลาของวิดีโอ (นาที)
                      </Row>
                      <Row
                        justify={"center"}
                        style={{ background: "white", padding: "16px 0" }}
                      >
                        90
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row
                        justify={"center"}
                        style={{ background: "#FBF6FF", padding: "16px 0" }}
                      >
                        ระยะเวลาโฆษณา (นาที)
                      </Row>
                      <Row
                        justify={"center"}
                        style={{
                          background: "white",
                          padding: "16px 0",
                          color: "red",
                        }}
                      >
                        24
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            }
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default Scene;
