import "./index.less"

const LandingPage = (props) => {

  var user = JSON.parse(localStorage.getItem("stt"));
  if (user) {
    window.location.href = `${process.env.REACT_APP_FRONT_URL}dashboard`
  } else {
    window.location.href = process.env.REACT_APP_LANDING_PAGE
  }

  return (
    <></>
  );

};

export default LandingPage;
