import PropTypes from 'prop-types';
import { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { localeState } from '../../../utils/atoms';
import { useRecoilState } from 'recoil';
import { translate } from '../../../utils/libs/translate';

function StackedChart({ data, view }) {
    const [locale, setLocale] = useRecoilState(localeState) 
    let setHight = data?.length == 1? data?.length*550 : data?.length * 100

    useEffect(() => {
        var chart = am4core.create("stackChartDiv", am4charts.XYChart);
        chart.data = data

        if (view !== 'SuperAdmin') {

            //category (bottom label)
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "name"
            // categoryAxis.title.text = "Hours In a Day";

            //value (side label)
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Files";
            valueAxis.title.fontWeight = "bold";

            //top stack (detected)
            let series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.valueY = "detected";
            series2.dataFields.categoryX = "name"
            series2.columns.template.fill = am4core.color("#B15EFF");
            series2.name = translate("Detected");
            series2.columns.template.tooltipText = "Detected\n{valueY} Files";
            series2.columns.template.strokeWidth = 0;

            //bot stack (undetected)
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "undetected";
            series.dataFields.categoryX = "name"
            series.columns.template.fill = am4core.color("#E5D4FF");
            series.name = translate("Undetected");
            series.columns.template.tooltipText = "Undetected\n{valueY} Files";
            series.columns.template.strokeWidth = 0;
            

            chart.legend = new am4charts.Legend();
            chart.legend.valueLabels.template.text = "";

            // stacked configuration
            // series.stacked = true;
            // series2.stacked = true;
            am4core.options.autoDispose = true;

            
        } else {
            chart.padding(40, 40, 40, 40);
            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            // categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.dataFields.category = "org_name";
            categoryAxis.renderer.inversed = true;
            // categoryAxis.renderer.grid.template.disabled = true;

            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.numberFormatter = new am4core.NumberFormatter();
            valueAxis.numberFormatter.numberFormat = "#"
            valueAxis.title.text = "Files";
            valueAxis.min = 0  
            valueAxis.maxPrecision = 0    


            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryY = "org_name";
            series.dataFields.valueX = "task_count";
            series.tooltipText = "{valueX.value}"
            series.columns.template.strokeOpacity = 0;
            series.columns.template.column.cornerRadiusBottomRight = 5;
            series.columns.template.column.cornerRadiusTopRight = 5;


            let labelBullet = series.bullets.push(new am4charts.LabelBullet())
            labelBullet.label.horizontalCenter = "left";
            labelBullet.label.dx = 10;
            labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
            labelBullet.locationX = 1;

            if (view !== 'SuperAdmin') {
                categoryAxis.dataFields.category = "label";
            }

            series.columns.template.adapter.add("fill", function (fill, target) {
                return am4core.color("#6794dc")
            });

            categoryAxis.sortBySeries = series;
            chart.data = JSON.parse(JSON.stringify(data));
        }

        return () => {
            chart.dispose();
        };
    }, [data, view, locale])

    return (
        <div id='stackChartDiv' className='chart-container' style={{height:setHight+'px'}}></div>
    )
}
StackedChart.propTypes = {
    data: PropTypes.any.isRequired,
    view: PropTypes.string
};
export default StackedChart