import { EditOutlined } from "@ant-design/icons";
import ProfileForm from "./ProfileForm";
import { Button, Drawer, Space } from "antd";
import { useEffect, useState } from "react";
import "./style/Profile.less";
import { edit_profile, upload_profilepic } from "../services/user";
import { translate } from "../utils/libs/translate";
import { Grid } from "antd";
import useNotification from "./Notification";

const DrawerWidth = (size: string) => {
  switch (size) {
    case "sm": {
      return 200;
    }
    case "md": {
      return 300;
    }
    case "xl": {
      return 400;
    }
    case "xxl": {
      return 400;
    }
  }
};

const Profile = ({
  userData,
  form,
  isProfileOpen,
  onClose,
  isEdit,
  setIsEdit,
  passwordFormVisible,
  setPasswordFormVisible,
  getProfile,
}: any) => {
  const { openNotification, contextHolder } = useNotification();
  const [imageUrl, setImageUrl] = useState<String | Object>(
    userData.profile_pic
  );
  const [previewImage, setPreviewImage] = useState<String | Object>("");
  const { useBreakpoint } = Grid;
  const screens: any = useBreakpoint();

  Object.keys(screens).filter((item) => {
    if (screens[item] === false) {
      delete screens[item];
    }
  });

  useEffect(() => {
    setImageUrl(userData.profile_pic);
  }, [userData]);

  const resetProfileEdit = () => {
    setIsEdit(false);
    setPasswordFormVisible(false);
    setPreviewImage("");
    setPasswordFormVisible(true);
    form.resetFields();
    setPasswordFormVisible(false);
  };

  const success = () => {
    openNotification("success", "Update profile success", "");
  };

  const errorNoti = () => {
    openNotification("error", "Error cannot edit profile", "");
  };

  const handleEdit = () => {
    isEdit ? setIsEdit(false) : setIsEdit(true);
    setPreviewImage("");
  };

  const togglePassword = () => {
    passwordFormVisible
      ? setPasswordFormVisible(false)
      : setPasswordFormVisible(true);
  };

  const onFinish = async (values: any) => {
    const formData = new FormData();
    if (values.profile) {
      formData.append("file", values.profile);
    }
    delete values.profile;
    try {
      const editRes = await edit_profile(values);
      const uploadRes = formData.get("file")
        ? await upload_profilepic(formData)
        : null;

      if (editRes.status === 200 && (!uploadRes || uploadRes.status === 200)) {
        await getProfile();
        setImageUrl((prev) => userData.profile_pic);
        setIsEdit(false);
        success();
      } else {
        errorNoti();
      }
    } catch (error) {
      errorNoti();
    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <Drawer
      width={DrawerWidth(Object.keys(screens)[Object.keys(screens).length - 1])}
      className="profile-drawer"
      title={translate("Profile")}
      placement="right"
      onClose={onClose}
      open={isProfileOpen}
    >
      {contextHolder}
      <Space direction="vertical" className="w-full">
        <ProfileForm
          form={form}
          onFinish={onFinish}
          isEdit={isEdit}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
          imageUrl={imageUrl}
          userData={userData}
          togglePassword={togglePassword}
          passwordFormVisible={passwordFormVisible}
        />
        {isEdit ? (
          <>
            <div className="profile-button-container">
              <Button
                className="profile-button cancel"
                onClick={resetProfileEdit}
              >
                {translate("Cancel")}
              </Button>
              <Button
                type="primary"
                className="profile-button"
                onClick={handleSubmit}
              >
                {translate("Save")}
              </Button>
            </div>
          </>
        ) : null}
      </Space>
      {/* edit icon button */}
      {isEdit ? null : (
        <div className="edit-btn-wrapper">
          <Button
            shape="circle"
            icon={<EditOutlined className="profile-edit-icon" />}
            onClick={handleEdit}
          />
        </div>
      )}
    </Drawer>
  );
};

export default Profile;
