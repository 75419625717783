import { AlignCenterOutlined } from "@ant-design/icons";
import React from "react";
import { Navigate, Outlet, redirect } from "react-router-dom";
import { useAuth } from "./AuthWrapper";

// check role user แยกตาม เมนู
const ProtectedRoute = ({ access, redirectPath = "/user/login", children }) => {
  const auth = useAuth();

  if (
    !auth.user
    // || (auth.user.role != access && access != undefined)
  ) {
    return <Navigate to={redirectPath} replace />;
  // } else if (auth.user || (access && access?.length > 1)) {
  }else{
    const result =
      access &&
      access.filter((d) => {
        if (d == auth.user.role) {
          return d;
        }
      });
    if (result?.length == 0) {
      return (
            // <Navigate to={auth.user.role == "User" ? "/task" : "/dashboard"} replace />
        <Navigate to={"/unauthorized"} replace />
      );
    }

    return children ? children : <Outlet />;
  }

  // return children ? children : <Outlet />;
};

// const ProtectedRoute = ({ access, redirectPath = "/login", children }) => {
//   const auth = useAuth();

//   if (
//     !auth.user
//     // || (auth.user.role != access && access != undefined)
//   ) {
//     return <Navigate to={redirectPath} replace />;
//   }

//   return children ? children : <Outlet />;
// };

export default ProtectedRoute;
